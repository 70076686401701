import { timeTransformer } from '@shein/common-function'

const actFormatMap = {
    // 年月日系列
    /**
     *'A-1': 'YYYY/MM/DD'
      'A-2': 'MM/DD/YYYY'
      'A-3': 'DD/MM/YYYY'
      'A-4': 'DD-MM-YYYY'
      'A-5': 'YYYY-MM-DD'
     */
    // 默认为A-3的站点：[de, fr, es, it, au, ca, se, mx, th, eur, nl, vn, ma, br, sg, nz, pl]
    FULL_DATE: {
        'A-1': ['pwar', 'pwaren', 'rwmar', 'mtw', 'mjp'],
        'A-2': ['m', 'rwm', 'pwus', 'rwmus', 'mil', 'masia'],
        'A-4': ['pwuk', 'rwmuk', 'mru', 'pwin', 'rwmin', 'mhk', 'mcl', 'mza']
    },
    // 月日系列
    // 默认为D-2的站点: [de, fr, es, it, au, ca, se, mx, th, eur, nl, vn, ma, br, sg, nz, pl]
    SHORT_DATE: {
        'D-1': ['m', 'rwm', 'pwus', 'rwmus', 'pwar', 'rwmar', 'mtw', 'mil', 'masia', 'mjp'],
        'D-3': ['pwuk', 'rwmuk', 'mru', 'pwin', 'rwmin', 'mhk', 'mcl', 'mza']
    },
    /** 年月日时分 系列 **/
    /*
      'F-1': 'MMM DD YYYY HH:mm'
      'F-2': 'DD-MM-YYYY HH:mm'
      'F-3': 'YYYY-MM-DD HH:mm'
      'F-4': 'DD/MM/YYYY HH:mm'
      'F-5': 'MM/DD/YYYY HH:mm'
      'F-6': 'DD MMM YYYY HH:mm'
      'F-7': 'YYYY/MM/DD HH:mm'
    */
    FULL_DATE_SHORT_TIME: {
      'F-7': ['pwar', 'pwaren', 'rwmar', 'mtw', 'mjp'],
      'F-5': ['m', 'rwm', 'pwus', 'rwmus', 'mil', 'masia'],
      'F-2': ['pwuk', 'rwmuk', 'mru', 'pwin', 'rwmin', 'mhk', 'mcl', 'mza']
    }
}

const ACTIVITY_DATE_TYPE = {
  fullDate: actFormatMap.FULL_DATE,
  shortDate: actFormatMap.SHORT_DATE,
  fullDateShortTime: actFormatMap.FULL_DATE_SHORT_TIME
}
const ACTIVITY_DEFAULT_FORMAT = {
  fullDate: 'A-3', // DD/MM/YYYY    日/月/年
  shortDate: 'D-2',  // DD/MM   日/月
  onlyShortTime: 'G-2',  // HH:mm 小时分钟
  onlyFullTime: 'G-1',  // HH:mm:ss 小时分钟秒
  fullDateShortTime: 'F-4' // DD/MM/YYYY HH:mm
}

/**
  * @param { String | Number } time
  * @param { 'fullDate' | 'shortDate' | 'onlyShortTime' | 'onlyFullTime' | 'fullDateShortTime' } type
  */
export function timezoneFormatFromSite(time, type = 'shortDate', options = {}) {
  if (!time) return time

  return timeTransformer({
    time,
    sDateMap: ACTIVITY_DATE_TYPE[type],
    defaultCode: ACTIVITY_DEFAULT_FORMAT[type]
  }, options)
}
